import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { createSubEvent, getSubEvents, updateSubEvent } from '../../actions/weddingAction';
import ViewSubEvents from './viewSubEvents';
import LoadingSpinner from '../loader';
import { toast } from 'react-toastify';
import { getWeddingDetail } from '../../actions/weddingAction';
// import './weddingStyles.css';
import './createSubEventStyles.css';
import PlacesAutocomplete from '../extra-components/placesAutocomplete';
import moment from 'moment';
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { Box } from '@material-ui/core';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';

const CreateSubEvents = () => {
  const location = useLocation();
  const url = decodeURI(location.search);
  const params = new URLSearchParams(url);
  const weddingId = params.get('weddingId');
  console.log('Wedding Id ', weddingId);

  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventTime, setEventTime] = useState('');
  const [eventPlace, setEventPlace] = useState({});
  const [subEvents, setSubEvents] = useState([]);
  const [editingSubEvent, setEditingSubEvent] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSubEvents = async () => {
    try {
      const response = await getSubEvents({ weddingId });
      setSubEvents(response?.subevents);
    } catch (error) {
      console.log('Error in Fetching existing SubEvent ', error);
      toast.error('Error fetching subevents');
    }
  };
  const fetchWeddingDetails = async () => {
    try {
      const response = await getWeddingDetail({ weddingId });
      setEventDate(response?.event?.start_date);
      setEventPlace(response?.event?.baraat_destination);
    } catch (error) {
      console.error('Error fetching date details:', error);
    }
  };
  useEffect(() => {
    fetchWeddingDetails();
    fetchSubEvents();
  }, []);

  const fetchWeddingSubEvents = async () => {
    try {
      const response = await getSubEvents({ weddingId });
      setSubEvents(response?.subevents);
    } catch (error) {
      console.error('Error fetching subevents details:', error);
    }
  };

  useEffect(() => {
    fetchWeddingDetails();
    fetchWeddingSubEvents();
  }, []);
  const handleCreateSubEvent = async (e) => {
    e.preventDefault();

    const data = {
      event_id: weddingId,
      subevent_name: eventName.trim(),
      start_time: eventTime,
      start_date: eventDate,
      location: eventPlace?.formatted_address,
      location_object: eventPlace,
    };

    try {
      setLoading(true);
      const result = await createSubEvent(data, weddingId);
      setLoading(false);
      console.log('Subevent created successfully:', result);
      if (result.status === true) {
        toast.success('SubEvent Created Successfully');
        // setSubEvents((prevSubEvents) => [
        //   ...prevSubEvents,
        //   {
        //     _id: result?.subevent_id,
        //     event_id: result?.wedding_event_id,
        //     subevent_name: eventName?.trim(),
        //     start_date: eventDate,
        //     start_time: eventTime,
        //     location: eventPlace?.trim(),
        //   },
        // ]
        // );
        fetchSubEvents();
        setEventName('');
        setEventDate('');
        setEventTime('');
        setEventPlace({});
      }
    } catch (error) {
      console.error('Failed to create subevent:', error);
      toast.error('Failed to create subEvent');
    }
  };

  const handleEditSubEvent = (subEvent) => {
    setEditingSubEvent(subEvent);
    setEventName(subEvent.subevent_name);
    setEventDate(subEvent.start_date);
    setEventTime(subEvent.start_time);
    setEventPlace(subEvent.location_object);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleUpdateSubEvent = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const updatedData = {
        event_id: weddingId,
        location: eventPlace?.formatted_address,
        location_object: eventPlace,
        start_time: eventTime,
        start_date: eventDate,
        subevent_name: eventName?.trim(),
      };
      const result = await updateSubEvent(weddingId, editingSubEvent?._id, updatedData);
      console.log('Subevent updated successfully:', result);
      setLoading(false);
      if (result.status === true) {
        toast.success('SubEvent Updated Successfully');
        fetchSubEvents();
        setEditingSubEvent(null);
        setEventName('');
        setEventDate('');
        setEventTime('');
        setEventPlace({});
      }
    } catch (error) {
      console.error('Failed to update subevent:', error);
      toast.error('Failed to update subevent');
      setLoading(false);
    }
  };

  const handleTimeChange = (newValue) => {
    if (newValue) {
      const formattedTime = dayjs(newValue).format('HH:mm A');
      console.log('Selected time is', newValue, 'Formatted', formattedTime);
      setEventTime(newValue);
    } else {
      setEventTime(null);
    }
  };

  return (
    <>
      <div className="container">
        <div className="header">
          <h1>{editingSubEvent ? 'Edit SubEvent' : 'Add SubEvent Details'}</h1>
        </div>
        <form className="subevent-form" onSubmit={editingSubEvent ? handleUpdateSubEvent : handleCreateSubEvent}>
          <div>
            <label>नाम</label>
            <input
              type="text"
              className="input-class"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              required
            />
          </div>
          <div>
            <label>तारीख</label>
            <input
              type="date"
              className="input-class"
              value={moment(eventDate).format('YYYY-MM-DD')}
              onChange={(e) => {
                let selectedDate = new Date(e.target.value);
                setEventDate(selectedDate);
              }}
            />
          </div>

          <label>समय</label>
          <Box sx={{ flex: '1 1 100%', padding: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['MobileTimePicker']}>
                <DemoItem>
                  <MobileTimePicker
                    value={dayjs(eventTime)}
                    label="समय"
                    onChange={(time_selected) => {
                      handleTimeChange(time_selected);
                    }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <div>
            <label>जगह</label>
            <PlacesAutocomplete
              type="eventPlace"
              label="Subevent Location"
              placeAddressObject={eventPlace}
              setSelectedPlace={setEventPlace}
              setChange={true}
            />
          </div>
          <button className="subevent-button" type="submit">
            {editingSubEvent ? 'Update SubEvent' : 'Create SubEvent'}
          </button>
        </form>
      </div>
      {loading && <LoadingSpinner message={editingSubEvent ? 'Updating SubEvent' : 'Creating SubEvent'} />}
      {subEvents?.length > 0 && (
        <ViewSubEvents
          subEvents={subEvents}
          onPostDeleteSubEvent={() => fetchSubEvents()}
          onEditSubEvent={handleEditSubEvent}
          uploader={true}
        />
      )}
    </>
  );
};

export default CreateSubEvents;
